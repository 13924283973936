import { mapState } from 'vuex'
import OkkuApi from '@/services/OkkuApi'

const sensorMixin = {
  computed: {
    ...mapState('common', [
      'rooms',
      'floors',
      'desks',
      'organisationUrls'
    ])
  },
  methods: {
    getSensors() {
      OkkuApi.getSensors().then(sensors => {
        this.allSensors = sensors
      })
    },
    isAssignedToRoom(sensor) {
      return (
        this.rooms.filter(room => room.id === sensor.wmt_object_id)
          .length > 0
      )
    },
    isAssignedToDesk(sensor) {
      return (
        this.desks.filter(desk => desk.id === sensor.wmt_object_id)
          .length > 0
      )
    },

    getSensorRoomName(sensor) {
      const rooms = this.rooms.filter(
        room => room.id === sensor.wmt_object_id
      )
      if (rooms && rooms.length) {
        return rooms[0].name
      }
      // TODO: revise if room field is dropped from sensors table
      return sensor.room
    },
    getSensorDeskName(sensor) {
      const desks = this.desks.filter(
        desk => desk.id === sensor.wmt_object_id
      )
      if (desks && desks.length) {
        return desks[0].name
      }
      // TODO: revise if desk field is dropped from sensors table
      return sensor.desk
    }
  }
}

export default sensorMixin
