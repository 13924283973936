<template>
  <div class="page-container">
    <section class="section-settings">
      <div class="container">
        <div class="jcsb">
          <div class="">
            <b-alert
              dismissible
              fade
              :variant="alertVariant"
              :show="dismissCountDown"
              @dismiss-count-down="
                countdown => (dismissCountDown = countdown)
              "
            >
              {{ alertText }}
            </b-alert>
            <h1 class="title-1">
              Sensors
            </h1>
          </div>
          <div class="map-floors">
            <floors-dropdown
              v-bind="{
                floors,
                currentFloor
              }"
              :on-floor-selection="handleFloorSelection"
            />
            <template v-if="isDashboardAdmin">
              <span
                class="btn btn-secondary edit-svg"
                @click="changeMode()"
                >{{ $t('edit') }}</span
              >
            </template>
          </div>
        </div>

        <div class="row">
          <div class="col-12">
            <b-tabs v-model="activetab" pills class="map-tabs">
              <b-tab active>
                <template v-slot:title>
                  <font-awesome-icon icon="layer-group" />
                </template>
                <b-card-text>
                  <template v-if="editMode">
                    <SvgEditorSensors
                      :current-floor="currentFloor"
                      :sensors="sensors"
                      @updateAlert="updateSvgAlert"
                      @updateMode="updateMode"
                    />
                  </template>
                  <template v-else>
                    <div class="floorplan-values">
                      <span
                        class="floorplan-values--item"
                        :class="{
                          current: currentValue == 'temperature'
                        }"
                        @click="currentValue = 'temperature'"
                        >{{ $t('temperature') }}</span
                      >
                      <span
                        class="floorplan-values--item"
                        :class="{
                          current: currentValue == 'co2'
                        }"
                        @click="currentValue = 'co2'"
                        >{{ $t('co2') }}</span
                      >
                      <span
                        class="floorplan-values--item"
                        :class="{
                          current: currentValue == 'humidity'
                        }"
                        @click="currentValue = 'humidity'"
                        >{{ $t('humidity') }}</span
                      >
                      <span
                        class="floorplan-values--item"
                        :class="{
                          current: currentValue == 'sound_avg'
                        }"
                        @click="currentValue = 'sound_avg'"
                        >{{ $t('sound') }}</span
                      >
                      <span
                        class="floorplan-values--item"
                        :class="{
                          current: currentValue == 'occupancy'
                        }"
                        @click="currentValue = 'occupancy'"
                        >{{ $t('occupancy') }}</span
                      >
                      <span
                        class="floorplan-values--item"
                        :class="{
                          current: currentValue == 'motion'
                        }"
                        @click="currentValue = 'motion'"
                        >{{ $t('motion') }}</span
                      >
                      <span
                        class="floorplan-values--item"
                        :class="{
                          current: currentValue == 'light'
                        }"
                        @click="currentValue = 'light'"
                        >{{ $t('light') }}</span
                      >
                      <span
                        class="floorplan-values--item"
                        :class="{
                          current: currentValue == 'counter'
                        }"
                        @click="currentValue = 'counter'"
                        >{{ $t('counters') }}</span
                      >
                    </div>
                    <SvgMap
                      :current-floor="currentFloor"
                      :desks="desks"
                      :sensors="sensors"
                      :floors="floors"
                      :rooms="rooms"
                      :organisation="organisation"
                      :current-value="currentValue"
                      :selected-sensor="selectedSensor"
                      @updateSelectedSensor="updateSelectedSensor"
                    />
                  </template>
                </b-card-text>
              </b-tab>
              <b-tab>
                <template v-slot:title>
                  <font-awesome-icon icon="list" />
                </template>
                <b-card-text>
                  <div class="filters-row tar">
                    <input
                      id="table-filter"
                      ref="filter"
                      v-model="filter"
                      style="width: 180px"
                      type="text"
                      class="form-control"
                      :placeholder="$t('filterTable')"
                    />
                  </div>
                  <div>
                    <b-table
                      :filter="filter"
                      class="table-style table-settings"
                      hover
                      :items="sensors"
                      :fields="fields"
                    >
                      <template #cell(sensor_id)="data">
                        <b-button
                          :to="{
                            name: 'SensorHistory',
                            params: {
                              sensorId: data.item.sensor_id
                            }
                          }"
                          variant="link"
                          class="pl-0"
                        >
                          {{ data.value }}
                        </b-button>
                      </template>
                      <template #cell(datetime)="data">
                        <span
                          :class="
                            formatDatetimeClass(data.item.datetime)
                          "
                        >
                          {{ data.value }}
                        </span>
                      </template>
                    </b-table>
                  </div>
                </b-card-text>
              </b-tab>
            </b-tabs>
          </div>
        </div>
      </div>
    </section>
    <b-modal id="modal-sensor" hide-footer>
      <template v-slot:modal-title>
        <span v-if="isAssignedToRoom(selectedSensor)">
          {{ $t('room') }}:
          <strong>{{ getSensorRoomName(selectedSensor) }}</strong>
        </span>
        <span v-if="isAssignedToDesk(selectedSensor)">
          {{ $t('desk') }}:
          <strong>{{ getSensorDeskName(selectedSensor) }}</strong>
        </span>
        , {{ $t('sensorId') }}:
        <strong>{{ selectedSensor['sensor_id'] }}</strong>
        , {{ $t('sensorType') }}:
        <strong>{{ selectedSensor['sensor_type'] }}</strong>
      </template>
      <div class="d-block">
        <p>
          {{ $t('lastMessage') }}:
          <strong>{{
            selectedSensor.datetime | moment('MMM Do YYYY, H:mm')
          }}</strong>
        </p>
        <div
          v-if="selectedSensor['temperature']"
          class="stat-excerpt horizontal "
        >
          <div class="stat-excerpt--left">
            <font-awesome-icon icon="thermometer-half" />
            <div class="title">
              {{ $t('temperature') }}
            </div>
          </div>
          <span class="stat-value">
            <span>{{ selectedSensor['temperature'] }}</span>
            °C
          </span>
        </div>
        <div
          v-if="selectedSensor['co2']"
          class="stat-excerpt horizontal "
        >
          <div class="stat-excerpt--left">
            <font-awesome-icon icon="cloud" />
            <div class="title">
              {{ $t('co2') }}
            </div>
          </div>
          <span class="stat-value">
            <span>{{ selectedSensor['co2'] }}</span> ppm
          </span>
        </div>
        <div
          v-if="selectedSensor['humidity']"
          class="stat-excerpt horizontal "
        >
          <div class="stat-excerpt--left">
            <font-awesome-icon icon="tint" />
            <div class="title">
              {{ $t('humidity') }}
            </div>
          </div>
          <span class="stat-value">
            <span>{{ selectedSensor['humidity'] }}</span>
            %
          </span>
        </div>
        <div
          v-if="selectedSensor['occupancy']"
          class="stat-excerpt horizontal "
        >
          <div class="stat-excerpt--left">
            <font-awesome-icon icon="users" />
            <div class="title">
              {{ $t('occupancy') }}
            </div>
          </div>
          <span class="stat-value">
            <span>{{ selectedSensor['occupancy'] }}</span>
          </span>
        </div>
        <div
          v-if="selectedSensor['motion']"
          class="stat-excerpt horizontal "
        >
          <div class="stat-excerpt--left">
            <font-awesome-icon icon="walking" />
            <div class="title">
              {{ $t('motion') }}
            </div>
          </div>
          <span class="stat-value">
            <span>{{ selectedSensor['motion'] }}</span>
          </span>
        </div>
        <div
          v-if="selectedSensor['light']"
          class="stat-excerpt horizontal "
        >
          <div class="stat-excerpt--left">
            <font-awesome-icon icon="lightbulb" />
            <div class="title">
              {{ $t('light') }}
            </div>
          </div>
          <span class="stat-value">
            <span>{{ selectedSensor['light'] }}</span>
          </span>
        </div>
        <div
          v-if="selectedSensor['sound_avg']"
          class="stat-excerpt horizontal "
        >
          <div class="stat-excerpt--left">
            <font-awesome-icon icon="volume-up" />
            <div class="title">{{ $t('sound') }} Avarage</div>
          </div>
          <span class="stat-value">
            <span>{{ selectedSensor['sound_avg'] }}</span>
            dB(A)
          </span>
        </div>
        <div
          v-if="selectedSensor['sound_peak']"
          class="stat-excerpt horizontal "
        >
          <div class="stat-excerpt--left">
            <font-awesome-icon icon="volume-up" />
            <div class="title">{{ $t('sound') }} - Peak</div>
          </div>
          <span class="stat-value">
            <span>{{ selectedSensor['sound_peak'] }}</span>
            dB(A)
          </span>
        </div>
        <div
          v-if="selectedSensor['counter']"
          class="stat-excerpt horizontal "
        >
          <div class="stat-excerpt--left">
            <font-awesome-icon icon="wifi" />
            <div class="title">
              {{ $t('counters') }}
            </div>
          </div>
          <span class="stat-value">
            <span>{{ selectedSensor['counter'] }}</span>
          </span>
        </div>
        <b-button
          :to="{
            name: 'SensorHistory',
            params: { sensorId: selectedSensor['sensor_id'] }
          }"
          class="btn btn-secondary full"
          style="margin-top: 15px"
        >
          <font-awesome-icon
            icon="chart-line"
            style="font-size: 19px; margin-right: 8px; top: 2px;position: relative;"
          />
          {{ $t('historicalData') }}
        </b-button>
      </div>
    </b-modal>
  </div>
</template>

<script>
import Vue from 'vue'
import { mapState, mapGetters } from 'vuex'
import vueMoment from 'vue-moment'
import moment from 'moment'
import sensorMixin from '@/mixins/sensorMixins'

import FloorsDropdown from '@/components/common/FloorsDropdown'
import SvgEditorSensors from '@/components/common/SvgEditorSensors'
import SvgMap from '@/components/common/SvgMap'

import { SET_SENSOR_HISTORY_PARAMS } from '@/store/modules/common/mutationTypes'

Vue.use(vueMoment)

export default {
  name: 'SensorsDataPage',
  components: {
    FloorsDropdown,
    SvgEditorSensors,
    SvgMap
  },
  mixins: [sensorMixin],
  metaInfo: {
    meta: [
      {
        vmid: 'viewport',
        name: 'viewport',
        content:
          'width=device-width, initial-scale=1, maximum-scale=1, user-scalable=no'
      }
    ]
  },
  data() {
    return {
      currentFloor: {},
      activetab: 0,
      editMode: false,
      allSensors: [],
      sensors: [],
      currentValue: 'temperature',
      selectedSensor: {},
      dismissCountDown: 0,
      alertVariant: 'success',
      alertText: '',
      filter: '',
      fields: [
        {
          key: 'sensor_id',
          label: this.$t('sensorId')
        },
        {
          key: 'sensor_type',
          label: this.$t('sensorType'),
          sortable: true
        },
        {
          key: 'room',
          label: `${this.$t('room')} / ${this.$t('desk')}`,
          formatter: (value, key, item) =>
            this.isAssignedToDesk(item)
              ? this.getSensorDeskName(item)
              : this.getSensorRoomName(item),
          filterByFormatted: true
        },
        {
          key: 'datetime',
          label: this.$t('lastMessage'),
          formatter: (value, key, item) =>
            value ? moment(value).format('MMM Do YYYY, HH:mm') : '',
          filterByFormatted: true,
          sortable: true
        },
        {
          key: 'co2',
          label: this.$t('co2'),
          sortable: true
        },
        {
          key: 'humidity',
          label: this.$t('humidity'),
          sortable: true
        },
        {
          key: 'temperature',
          label: this.$t('temperature'),
          sortable: true
        },
        {
          key: 'motion ',
          label: this.$t('motion'),
          sortable: true
        },
        {
          key: 'light',
          label: this.$t('light'),
          sortable: true
        },
        {
          key: 'counter',
          label: this.$t('counter'),
          sortable: true
        },
        {
          key: 'vdd',
          label: this.$t('vdd'),
          sortable: true
        }
      ]
    }
  },
  computed: {
    ...mapState('common', {
      organisation: '$organisation',
      isDashboardAdmin: '$isDashboardAdmin',
      isCustomerAdmin: '$isCustomerAdmin'
    }),
    ...mapState('common', [
      'rooms',
      'floors',
      'desks',
      'organisationUrls'
    ]),
    ...mapGetters('common', ['sensorsEnabled'])
  },
  watch: {
    organisation() {
      this.getSensors()
    },
    sensorsEnabled(enabled) {
      if (!enabled) {
        this.$router.push('/')
      }
    },
    floors() {
      const [currentFloor] = this.floors
      this.currentFloor = currentFloor
    },
    currentFloor() {
      this.sensors = this.allSensors.filter(
        sensor =>
          sensor.wmt_object_id === 'null' ||
          this.rooms.filter(
            room =>
              sensor.wmt_object_id === room.id &&
              room.floorId === this.currentFloor.id
          ).length > 0 ||
          this.desks.filter(
            desk =>
              sensor.wmt_object_id === desk.id &&
              desk.floorId === this.currentFloor.id
          ).length > 0
      )
    },
    allSensors() {
      this.sensors = this.allSensors.filter(
        sensor =>
          sensor.wmt_object_id === 'null' ||
          this.rooms.filter(
            room =>
              sensor.wmt_object_id === room.id &&
              room.floorId === this.currentFloor.id
          ).length > 0 ||
          this.desks.filter(
            desk =>
              sensor.wmt_object_id === desk.id &&
              desk.floorId === this.currentFloor.id
          ).length > 0
      )
    }
  },
  mounted() {
    if (this.organisation) {
      this.getSensors()
    }

    if (this.floors.length > 0) {
      const [currentFloor] = this.floors
      this.currentFloor = currentFloor
    }
  },

  methods: {
    formatDatetimeClass(datetime) {
      console.log(datetime)
      if (moment(datetime).isBefore(moment().subtract(1, 'days'))) {
        return 'text-danger'
      }
      return 'text-success'
    },
    updateSelectedSensor(selectedSensor) {
      this.selectedSensor = selectedSensor
      this.$store.commit(`common/${SET_SENSOR_HISTORY_PARAMS}`, {
        sensorId: selectedSensor.sensor_id
      })
    },
    updateMode(editMode) {
      this.editMode = editMode
      this.getSensors()
    },
    changeMode() {
      if (this.editMode) {
        this.editMode = false
      } else {
        this.editMode = true
      }
    },
    updateSvgAlert(args) {
      this.dismissCountDown = 3
      this.alertVariant = args.variant
      this.alertText = args.text
    },
    handleFloorSelection(floor) {
      this.currentFloor = floor
    }
  }
}
</script>
<style lang="scss" scoped>
@import '../assets/scss/globals/vars.scss';
@import '../assets/scss/globals/mixins.scss';
@import '../assets/scss/globals/extend.scss';
strong {
  font-weight: 700;
}
.filters-row {
  margin-top: -40px;
}
.map-floors {
  text-align: right;
  //margin-top: -40px;
  font-size: 0;
  @include r(991) {
    text-align: left;
    margin-top: 5px;
  }
  .btn,
  .nav-pills {
    display: inline-block;
    vertical-align: top;
  }
  @include r(767) {
    text-align: left;
    margin-top: 10px;
    margin-bottom: 15px;
  }
  @include r(500) {
    .btn {
      width: 100%;
      margin-right: 0;
      margin-top: 10px;
    }
  }
}
.map-tabs::v-deep {
  padding-top: 17px;
  @include r(767) {
    padding-top: 0;
  }
  .nav-link {
    color: $basecolor;
    &.active {
      background-color: $basecolor;
      color: #fff;
    }
  }

  .table-responsive {
    margin-top: 16px;
  }
}
.table-style td {
  @include r(600) {
    font-size: 12px !important;
    &:before {
      display: block;
      font-size: 10px;
    }
  }
}

.title-1 {
  margin-bottom: 0;
}
.floorplan-values {
  font-size: 0;
  text-align: right;
  margin-top: -34px;
  @include r(767) {
    text-align: left;
    margin-top: 10px;
  }
  &--item {
    cursor: pointer;
    display: inline-block;
    vertical-align: top;
    margin: 0 3px 6px;
    background-color: rgba(#fff, 1);
    transition: all $time;
    font-size: 10px;
    text-transform: uppercase;
    letter-spacing: 0.6px;
    padding: 6px 11px;
    color: $textcolor-med;
    border: 1px solid rgba($basecolor, 0.2);
    @include r(767) {
      margin-left: 0;
      margin-right: 6px;
    }
    &.current,
    &:hover {
      background-color: $basecolor;
      color: #fff;
    }
  }
}
.stat-excerpt {
  border-radius: 10px;
  margin-bottom: 15px;
  position: relative;
  width: 100%;
  background: -webkit-gradient(
    linear,
    left top,
    left bottom,
    from(#f8f9ff),
    to(#fff)
  );
  background: linear-gradient(to bottom, #f8f9ff, #fff);
  border: 1px solid $bordercolor;
  color: $textcolor;
  &.with-stat {
    max-width: calc(100% - 50px);
    @include r(660) {
      max-width: calc(100% - 40px);
    }
    .stat-icon {
      position: absolute;
      @extend %vam;
      right: -50px;
      max-width: 35px;
      display: block;
      img {
        width: 100%;
        max-width: 100%;
      }
      @include r(660) {
        max-width: 30px;
        right: -40px;
      }
    }
  }

  &.green {
    background-color: $green-dark;
    @include background-gradient($green-light, $green-dark, vertical);
    .stat-value {
      background-color: $green-light;
    }
  }
  &.blue {
    background-color: $blue-dark;
    @include background-gradient($blue-light, $blue-dark, vertical);
    .stat-value {
      background-color: $blue-light;
    }
  }
  &.orange {
    background-color: $orange-dark;
    @include background-gradient(
      $orange-light,
      $orange-dark,
      vertical
    );
    .stat-value {
      background-color: $orange-light;
    }
  }
  &.pink {
    background-color: $pink-dark;
    @include background-gradient($pink-light, $pink-dark, vertical);
    .stat-value {
      background-color: $pink-light;
    }
  }
  &.purple {
    background-color: $purple-dark;
    @include background-gradient(
      $purple-light,
      $purple-dark,
      vertical
    );
    .stat-value {
      background-color: $purple-light;
    }
  }
  &.yellow {
    background-color: $yellow-dark;
    @include background-gradient(
      $yellow-light,
      $yellow-dark,
      vertical
    );
    .stat-value {
      background-color: $yellow-light;
    }
  }
  &.green2 {
    background-color: $green2-dark;
    @include background-gradient(
      $green2-light,
      $green2-dark,
      vertical
    );
    .stat-value {
      background-color: $green2-light;
    }
  }
  &.green3 {
    background-color: #15aabf;
    @include background-gradient(#16d4ea, #15aabf, vertical);
    .stat-value {
      background-color: #16d4ea;
    }
  }

  padding: 45px 30px;
  .svg-inline--fa {
    font-size: 48px;
    display: block;
    color: rgba($textcolor, 0.4);
  }
  .title {
    margin-top: 20px;
    font-size: 14px;
    font-weight: 400;
    letter-spacing: 1.4px;
    strong {
      font-weight: 900;
      letter-spacing: 1px;
    }
  }
  .stat-value {
    display: inline-block;
    border-radius: 4px;
    padding: 7px 14px;
    box-shadow: inset 0 3px 8px rgba(0, 0, 0, 0.1);
    margin-top: 35px;
    font-size: 21px;
    letter-spacing: 0.5px;
    font-weight: 500;
  }

  &.horizontal {
    font-size: 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px 15px;
    margin-bottom: 10px;
    .svg-inline--fa {
      font-size: 22px;
      display: inline-block;
      vertical-align: middle;
      margin-right: 15px;
    }
    .title {
      display: inline-block;
      vertical-align: middle;
      margin-top: 0;
      font-size: 12px;
    }
    .stat-value {
      margin-top: 0;
      font-size: 14px;
      padding: 6px 10px;
    }
  }
}
.map-floors {
  max-width: 100%;
}
@include rm(991) {
  .jcsb {
    justify-content: space-between;
    display: flex;
    flex-flow: row wrap;
  }
  .nav-pills {
    white-space: normal;
  }
}
</style>
