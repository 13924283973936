var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"demo-container"},[_c('div',{staticClass:"edit-toolbar"},[_c('span',{staticClass:"btn btn-grey btn-icon",on:{"click":_vm.revertChange}},[_c('font-awesome-icon',{attrs:{"icon":"history"}})],1),_c('span',{staticClass:"btn btn-secondary",on:{"click":function($event){return _vm.saveChanges()}}},[_vm._v(_vm._s(_vm.$t('save')))]),_c('span',{staticClass:"btn btn-danger",on:{"click":_vm.cancelChanges}},[_vm._v(_vm._s(_vm.$t('cancel')))])]),_c('div',{staticClass:"canvas"},[_c('svg',{staticStyle:{"enable-background":"new 0 0 645 402"},attrs:{"id":"svgEdit","version":"1.1","xmlns":"http://www.w3.org/2000/svg","xmlns:xlink":"http://www.w3.org/1999/xlink","x":"0px","y":"0px","viewBox":"0 0 645 402","xml:space":"preserve"},on:{"mousemove":function($event){return _vm.onMouseMove($event)},"mouseup":function($event){return _vm.onMouseUp($event)},"click":function($event){return _vm.onClickItem($event, {})}}},[_c('g',{attrs:{"id":"floorSnippet"},domProps:{"innerHTML":_vm._s(_vm.currentFloor.svgSnippet)}}),_vm._l((_vm.items),function(item,index){return _c('g',{key:index,attrs:{"transform":'translate(' + item.x + ', ' + item.y + ')'}},[_c('circle',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover",modifiers:{"hover":true}}],attrs:{"cx":0,"cy":0,"r":5,"fill":item.color,"title":_vm.$t('sensorType') +
              ': ' +
              item.sensor_type +
              '; ' +
              _vm.$t('sensorId') +
              ': ' +
              item.sensor_id +
              '; ' +
              (_vm.isAssignedToDesk(item)
                ? _vm.$t('desk') + ': ' + _vm.getSensorDeskName(item)
                : _vm.$t('room') + ': ' + _vm.getSensorRoomName(item))},on:{"click":function($event){return _vm.onClickItem($event, item)}}}),(item.active)?_c('g',[_c('circle',{staticClass:"active",attrs:{"cx":0,"cy":0,"r":5},on:{"click":function($event){return _vm.onClickItem($event, item)},"_mouseout":function($event){return _vm.onMouseUpRegion($event, item)},"mousedown":function($event){return _vm.onMouseDownRegion($event, item)}}})]):_vm._e()])})],2)]),_c('b-modal',{attrs:{"id":"modal-route","on-enter-key-press":function () {
        _vm.editMode = false
        _vm.updateMode()
      },"hide-footer":"","hide-title":""}},[_c('div',{staticClass:"d-block text-center"},[_c('p',[_vm._v(_vm._s(_vm.$t('changesNotSaved')))]),_c('b-button',{staticClass:"mt-1",attrs:{"variant":"danger"},on:{"click":function($event){return _vm.$bvModal.hide('modal-route')}}},[_vm._v(" "+_vm._s(_vm.$t('no'))+" ")]),_c('b-button',{staticClass:"mt-1",attrs:{"primary":""},on:{"click":function () {
            _vm.editMode = false
            _vm.updateMode()
          }}},[_vm._v(" "+_vm._s(_vm.$t('yes'))+" ")])],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }