<!-- eslint-disable vue/no-v-html -->
<template>
  <div class="demo-container">
    <div class="edit-toolbar">
      <span class="btn btn-grey btn-icon" @click="revertChange">
        <font-awesome-icon icon="history" />
      </span>
      <span class="btn btn-secondary" @click="saveChanges()">{{
        $t('save')
      }}</span>
      <span class="btn btn-danger" @click="cancelChanges">{{
        $t('cancel')
      }}</span>
    </div>
    <div class="canvas">
      <svg
        id="svgEdit"
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        xmlns:xlink="http://www.w3.org/1999/xlink"
        x="0px"
        y="0px"
        viewBox="0 0 645 402"
        style="enable-background:new 0 0 645 402;"
        xml:space="preserve"
        @mousemove="onMouseMove($event)"
        @mouseup="onMouseUp($event)"
        @click="onClickItem($event, {})"
      >
        <g id="floorSnippet" v-html="currentFloor.svgSnippet" />
        <g
          v-for="(item, index) in items"
          :key="index"
          :transform="'translate(' + item.x + ', ' + item.y + ')'"
        >
          <!-- item -->
          <circle
            v-b-tooltip.hover
            :cx="0"
            :cy="0"
            :r="5"
            :fill="item.color"
            :title="
              $t('sensorType') +
                ': ' +
                item.sensor_type +
                '; ' +
                $t('sensorId') +
                ': ' +
                item.sensor_id +
                '; ' +
                (isAssignedToDesk(item)
                  ? $t('desk') + ': ' + getSensorDeskName(item)
                  : $t('room') + ': ' + getSensorRoomName(item))
            "
            @click="onClickItem($event, item)"
          />
          <g v-if="item.active">
            <circle
              :cx="0"
              :cy="0"
              :r="5"
              class="active"
              @click="onClickItem($event, item)"
              @_mouseout="onMouseUpRegion($event, item)"
              @mousedown="onMouseDownRegion($event, item)"
            />
          </g>
        </g>
      </svg>
    </div>
    <b-modal
      id="modal-route"
      :on-enter-key-press="
        () => {
          editMode = false
          updateMode()
        }
      "
      hide-footer
      hide-title
    >
      <div class="d-block text-center">
        <p>{{ $t('changesNotSaved') }}</p>
        <b-button
          variant="danger"
          class="mt-1"
          @click="$bvModal.hide('modal-route')"
        >
          {{ $t('no') }}
        </b-button>
        <b-button
          class="mt-1"
          primary
          @click="
            () => {
              editMode = false
              updateMode()
            }
          "
        >
          {{ $t('yes') }}
        </b-button>
      </div>
    </b-modal>
  </div>
</template>
<script>
import { each as _each } from 'lodash'
import { mapState } from 'vuex'

import OkkuApi from '@/services/OkkuApi'

import BootstrapModalHOC from '@/components/hocs/BootstrapModal'

const defaultProps = {
  hex: '#999999',
  a: 1
}
export default {
  name: 'SvgEditorSensors',
  components: {
    'b-modal': BootstrapModalHOC
  },
  props: {
    currentFloor: {
      type: Object,
      required: true
    },
    sensors: {
      type: Array,
      required: true
    }
  },
  data() {
    return {
      width: 1110,
      height: 692,
      colors: defaultProps,
      onChangeColorListener: null,
      showcolors: false,
      movetarget: null,
      activeItemIndex: null,
      items: [],
      editMode: true,
      lastChangedItem: null,
      lastChangedAction: null,
      lastChangedX: null,
      lastChangedY: null,
      changesDone: false,
      tools: {
        squaresize: 8,
        min_height: 10
      }
    }
  },
  computed: {
    ...mapState('common', {
      organisation: '$organisation'
    }),
    ...mapState('common', ['rooms', 'floors', 'desks'])
  },
  watch: {
    sensors() {
      this.createItemsArray()
    }
  },
  mounted() {
    this.createItemsArray()
  },
  created() {
    window.addEventListener('keydown', this.onKeyDown)
  },
  destroyed() {
    window.removeEventListener('keydown', this.onKeyDown)
  },
  methods: {
    updateMode() {
      this.$emit('updateMode', this.editMode)
    },
    getSensorCoordinates(currSensor) {
      let parent = {}
      let x = 0
      let y = 0
      if (currSensor.x || currSensor.y) {
        return [currSensor.x, currSensor.y]
      }
      if (currSensor.wmt_object_id != null) {
        this.desks.forEach((desk, index) => {
          if (currSensor.wmt_object_id === desk.id) {
            parent = desk
          }
        })
        this.rooms.forEach((room, index) => {
          if (currSensor.wmt_object_id === room.name) {
            parent = room
          }
        })
        if (parent.svgSnippet) {
          const snippet = parent.svgSnippet
          const svg = new DOMParser().parseFromString(
            snippet,
            'text/xml'
          )
          x = parseFloat(
            svg.getElementsByTagName('rect')[0].getAttribute('x')
          )
          y = parseFloat(
            svg.getElementsByTagName('rect')[0].getAttribute('y')
          )
        }
      } else {
        x = Math.floor(Math.random() * 100) + 1
        y = Math.floor(Math.random() * 100) + 1
      }
      return [x, y]
    },
    createItemsArray() {
      this.items = []
      _each(this.sensors, sensor => {
        const coordinates = this.getSensorCoordinates(sensor)
        this.items.push({
          ...sensor,
          x: parseFloat(coordinates[0]),
          y: parseFloat(coordinates[1]),
          r: 5,
          active: false,
          color: 'gray'
        })
      })
    },
    async saveChanges() {
      try {
        const movedSensors = this.items.filter(item => item.drag)
        if (!movedSensors.length) {
          this.updateAlert('warning', this.$t('noSensorMovement'))
          return
        }

        const sensors = []
        movedSensors.forEach(movedSensor => {
          sensors.push({
            sensor_id: movedSensor.sensor_id,
            wmt_object_id: movedSensor.wmt_object_id,
            x: movedSensor.x,
            y: movedSensor.y,
            sensor_type: movedSensor.sensor_type,
            sensor_external_id: movedSensor.sensor_external_id
          })
        })

        await OkkuApi.updateSensor(sensors)
        this.updateAlert('success', this.$t('changesSaved'))
      } catch (error) {
        this.updateAlert('danger', this.$t('changesNotSaved'))
      } finally {
        this.updateMode()
      }
    },
    revertChange() {
      if (this.lastChangedItem != null) {
        for (let i = 0; i < this.items.length; i += 1) {
          if (this.items[i].id === this.lastChangedItem) {
            if (this.lastChangedAction === 'MOVE') {
              this.items[i].x = this.lastChangedX
              this.items[i].y = this.lastChangedY
            }
          }
        }
      }
    },
    updateAlert(variant, text) {
      this.$emit('updateAlert', { variant, text })
    },
    cancelChanges() {
      if (this.changesDone) {
        this.$bvModal.show('modal-route')
      } else {
        this.editMode = false
        this.updateMode()
      }
    },
    onChange(val) {
      if (this.onChangeColorListener) {
        this.colors = val
        this.onChangeColorListener()
      }
    },
    onClickItem(event, item) {
      event.stopPropagation() // prevent parent from firing
      if (item.active) {
        return
      }
      // select item if not selected
      this.deactivateItems()
      item.active = true
      this.onChangeColorListener = () => {
        item.color = this.colors.hex
      }
      this.colors = defaultProps
      this.colors.hex = item.color
      this.activeItemIndex = this.items.indexOf(item)
    },
    deactivateItems() {
      this.items.forEach(i => {
        if (i) {
          i.active = false
        }
      })
    },
    onMouseDownRegion(event, item) {
      const offset = this.getMousePosition(event)
      if (item.active) {
        item.drag = {
          type: 'MOVE',
          x: item.x,
          y: item.y,
          mx: event.x,
          my: event.y,
          offsetX: offset.x - item.x,
          offsetY: offset.y - item.y
        }
      }

      this.movetarget = item
      this.lastChangedX = item.cx
      this.lastChangedY = item.cy
      this.changesDone = true
    },
    getMousePosition(evt) {
      const svg = document.getElementById('svgEdit')
      const CTM = svg.getScreenCTM()
      return {
        x: (evt.clientX - CTM.e) / CTM.a,
        y: (evt.clientY - CTM.f) / CTM.d
      }
    },

    onMouseUp(event, element) {
      this.movetarget = null
    },

    onMouseMove(event) {
      if (this.movetarget === null) return
      const item = this.movetarget
      this.lastChangedItem = item.id
      this.lastChangedAction = item.drag.type

      if (item.active && item.drag !== undefined) {
        event.preventDefault()
        const coord = this.getMousePosition(event)
        item.x = coord.x - item.drag.offsetX
        item.y = coord.y - item.drag.offsetY
      }
    },
    onKeyDown(e) {
      for (let i = 0; i < this.items.length; i += 1) {
        if (this.items[i].active) {
          const item = this.items[i]
          this.lastChangedItem = item.id
          if (e.keyCode === '38') {
            // up arrow
            e.preventDefault()
            item.y = parseFloat(item.y - 0.1)
          } else if (e.keyCode === '40') {
            // down arrow
            e.preventDefault()
            item.y = parseFloat(item.y + 0.1)
          } else if (e.keyCode === '37') {
            // left arrow
            e.preventDefault()
            item.x = parseFloat(item.x - 0.1)
          } else if (e.keyCode === '39') {
            // right arrow
            e.preventDefault()
            item.x = parseFloat(item.x + 0.1)
          }
        }
      }
    },
    isAssignedToRoom(sensor) {
      return (
        this.rooms.filter(room => room.id === sensor.wmt_object_id)
          .length > 0
      )
    },
    isAssignedToDesk(sensor) {
      return (
        this.desks.filter(desk => desk.id === sensor.wmt_object_id)
          .length > 0
      )
    },
    getSensorRoomName(sensor) {
      const rooms = this.rooms.filter(
        room => room.id === sensor.wmt_object_id
      )
      if (rooms && rooms.length) {
        return rooms[0].name
      }
      // TODO: revise if room field is dropped from sensors table
      return sensor.room
    },
    getSensorDeskName(sensor) {
      const desks = this.desks.filter(
        desk => desk.id === sensor.wmt_object_id
      )
      if (desks && desks.length) {
        return desks[0].name
      }
      // TODO: revise if desk field is dropped from sensors table
      return sensor.desk
    }
  }
}
</script>
<style scoped lang="scss">
svg {
  margin: 0 auto;
  width: 100%;
}

svg .ctrl-rect {
  fill: #ffffff;
  stroke: #222222;
  stroke-width: 1;
}

svg .ctrl-bounds {
  fill-opacity: 0;
  stroke: #222222;
  stroke-width: 1;
  stroke-linecap: round;
  stroke-dasharray: 2, 4;
}
.canvas {
  text-align: center;
  overflow-x: auto;
}
.canvas svg {
  background: #fff;
}

.edit-toolbar {
  text-align: right;
  margin-top: -40px;
  margin-bottom: 20px;
  font-size: 0;
  .btn {
    display: inline-block;
    vertical-align: top;
    margin-right: 10px;
    height: 40px;
    &:last-child {
      margin-right: 0;
    }
  }
  @media all and (max-width: 500px) {
    text-align: left;
    margin-top: 10px;
  }
}
rect {
  cursor: pointer;
}
</style>
